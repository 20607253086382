import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'

// import './plugins/click-away'

import './scss/app.scss';

import vuetify from './plugins/vuetify'

Vue.use(Antd);

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

import '@fortawesome/fontawesome-free/css/all.css'
import axios from 'axios'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
axios.defaults.baseURL = 'https://my.qrcbn.com/api/'

var AUTH_TOKEN = localStorage.getItem('access_token')
if (AUTH_TOKEN) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var token = localStorage.getItem('access_token')
    if (!token) {
      next({
        path: '/sign-in',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')